import "./styles.scss";

import * as Yup from "yup";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RegisterStatus } from "Shared/Enums";
import SelectFieldContainer from "Components/RegisterForm/SelectFieldContainer";
import TextFieldsContainer from "Components/RegisterForm/TextFieldsContainer";
import axios from "axios";
import dayjs from "dayjs";
import { getClientToken } from "Shared/baseURL";
import { motion } from "framer-motion";
import { registerUser } from "./Api";
import { useNotify } from "Utils/hooks";

const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  confirmEmail: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("email")], "Email does not match."),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  howYouHeardOfUs: Yup.string().required("Required"),
  hearFromUsDescription: Yup.string(),
  countryFrom: Yup.object()
    .typeError("Invalid country")
    .shape({
      name: Yup.string().required("Required"),
      code: Yup.string(),
    })
    .nullable()
    .required("Required"),
  username: Yup.string().required("Required"),
  invitationCode: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  otherTerm: Yup.string().when("gender", {
    is: "Other term preferred",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().notRequired(),
  }),
  race: Yup.string().required("Required"),
  dateOfBirth: Yup.date()
    .typeError("Invalid date")
    .max(dayjs().subtract(13, "year"), "Must be at least 13 years old.")
    .required("Required"),
  password: Yup.string()
    .matches(passReg, {
      message:
        "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter",
    })
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Password doest not match."),
});

const Register = () => {
  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [disableEmailFields, setDisableEmailFields] = useState(false);
  const [userLink, setUserLink] = useState("");

  // router
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      handleSearchEmail(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchEmail = async (email) => {
    try {
      setIsLoading(true);
      setSearchEmail(email);
      const token = await getClientToken();
      await axios.get(`registereds/[email=${email}].json`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDisableEmailFields(true);
    } catch (error) {
      notify(error.message, { variant: "error" });
      setDisableEmailFields(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values, setSubmitting) => {
    setAlertMessage("");
    try {
      setEmail(values.email);
      let data = {
        ...values,
        dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
        showTutorial: true,
        // cityFrom: "",
        countryFrom: values.countryFrom.name,
      };

      if (data.hearFromUsDescription) {
        data.howYouHeardOfUs = `${data.howYouHeardOfUs} (${data.hearFromUsDescription})`;
      }

      if (!data.disabilitiesDescription) {
        delete data.disabilitiesDescription;
      }

      delete data.hearFromUsDescription;
      delete data.otherTerm;
      delete data.confirmEmail;
      delete data.confirmPassword;

      setSubmitting(true);
      const res = await registerUser(data);
      if (res.message) {
        throw res;
      }
      setUserLink(res?.data?.uuid);
      setResponseStatus(RegisterStatus.OK);
    } catch (error) {
      if (error.status === 409) {
        if (error.message.includes("already exists")) {
          handleDuplicationError(error.message);
        } else {
          setResponseStatus(RegisterStatus[error.message]);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleDuplicationError = (message) => {
    if (message.includes("email")) {
      setAlertMessage("User with this email already exists.");
    } else if (message.includes("username")) {
      setAlertMessage("User with this username already exists.");
    } else {
      setAlertMessage(message);
    }
  };

  return isLoading ? (
    <div className="center mt-5">
      <CircularProgress />
    </div>
  ) : (
    <Box className="register-page">
      <Container maxWidth="md">
        <h3>Register to Join Kewordal</h3>
        <Box mt={3}>
          {responseStatus ? (
            <Box sx={{ px: 2, py: 2 }}>
              {responseStatus === RegisterStatus.OK && (
                <div>
                  {/* <p>
                    Kewordal is still in BETA and we are authorizing access to
                    the platform in stages.
                  </p> */}
                  <p>
                    Please Check you Email for activation on &nbsp;
                    <strong>Kewordal.</strong>
                  </p>
                  <div>
                    <ul>
                      <li>
                        If do not see an email after a few minutes, please check
                        your spam / junk mail folder. If it is there, please add
                        "info@kewordal.com" to your "Safe Sender List".
                      </li>
                      <li className="my-3">
                        If you did not receive a confirmation email, please go
                        to "Forgot Password" on the "landing-page" and use the
                        same email address. This will indicate whether it is
                        registered with us and an email will be sent to you if
                        it is.
                      </li>
                      <li>
                        If it is not registered, please sign-up again checking
                        carefully on any spelling. You can always contact us
                        using the link in the footer below to inform us that you
                        are having difficulties, and we will do our best to help
                        you resolve the issue.
                      </li>
                    </ul>
                  </div>
                  {/* <p>
                    We will let you know as soon as your access has been
                    approved.
                  </p> */}

                  <p>Thank you, from the Kewordal Team</p>
                </div>
              )}
              {responseStatus === RegisterStatus.USER_SIGNED_UP && (
                <div>
                  <p>
                    It looks like you have already signed up. Please continue to{" "}
                    <Link to="/auth/login">Log in</Link>
                  </p>
                </div>
              )}
              {responseStatus === RegisterStatus.USER_REGISTERED && (
                <div>
                  <p>It looks like you've already registered with us.</p>
                  <p>
                    You are in a queuing system to join an incredibly exciting
                    social education & revision learning platform that enables
                    learners to build Kewordal lists for exams and tests to
                    accelerate their academic attainment.
                  </p>
                  <p>
                    We will contact you shortly to integrate you on to our
                    platform.
                  </p>
                  <p>Thank you, from the Kewordal Team</p>
                </div>
              )}
              {responseStatus === RegisterStatus.USER_APPROVED && (
                <div>
                  <p>
                    You have already registered with us. Please proceed to{" "}
                    <Link to={`/auth/login/?email=${email}`}>Sign up</Link>
                  </p>
                </div>
              )}
            </Box>
          ) : (
            <Formik
              // enableReinitialize
              initialValues={{
                email: searchEmail ?? "",
                firstName: "",
                lastName: "",
                confirmEmail: searchEmail ?? "",
                username: "",
                dateOfBirth: dayjs().subtract(25, "year").subtract(1, "day"),
                password: "",
                confirmPassword: "",
                invitationCode: "",
                disabilities: "no",
                disabilitiesDescription: "",
                gender: "Male",
                otherTerm: "",
                race: "",
                title: "",
                countryFrom: null,
                howYouHeardOfUs: "",
                hearFromUsDescription: "",
              }}
              validationSchema={RegisterSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Form autoComplete="off">
                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={3}
                      sx={{ mb: 2 }}
                      className="form-box"
                    >
                      <TextFieldsContainer
                        formValues={values}
                        disableEmailFields={disableEmailFields}
                      />
                      <SelectFieldContainer
                        formValues={values}
                        setFieldValue={setFieldValue}
                      />
                      <Grid item xs={12}>
                        <Box>
                          <p className="text-center black">
                            By clicking{" "}
                            <span className="fw-700">'register'</span>
                            , you are agreeing to our
                            <br />
                            <Link to="/terms-of-service" className="text-links">
                              Terms of Service
                            </Link>
                            ,{" "}
                            <Link to="/privacy-policy" className="text-links">
                              Privacy Policy
                            </Link>
                            ,{" "}
                            <Link to="/cookie-policy" className="text-links">
                              Cookie Policy
                            </Link>
                            , and{" "}
                            <Link to="/safety-policy" className="text-links">
                              Safety Policy
                            </Link>
                            .
                          </p>
                        </Box>
                        <Box className="center">
                          <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            sx={{
                              letterSpacing: "3px",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                            }}
                          >
                            REGISTER
                          </Button>
                        </Box>
                        {alertMessage && (
                          <motion.div
                            initial={{
                              opacity: 0,
                              y: 50,
                            }}
                            animate={{
                              opacity: 1,
                              y: 0,
                            }}
                            className="mt-4"
                          >
                            <Alert severity="error">
                              <AlertTitle>Error</AlertTitle>
                              {alertMessage}
                            </Alert>
                          </motion.div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {isSubmitting && <LinearProgress />}
                      </Grid>
                    </Grid>
                  </Form>
                </LocalizationProvider>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Register;
