import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { CalendarMonth, InfoOutlined } from "@mui/icons-material";

import { DatePicker } from "formik-mui-x-date-pickers";
import { TextField } from "formik-mui";
import { Titles } from "Shared/Enums";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";

const CompleteProfileTextFields = ({ formValues, disableEmailFields }) => {
  // state
  const [renderTitle, setRenderTitle] = useState(true);
  const [dobField, setDobField] = useState(false);

  useEffect(() => {
    const { dateOfBirth } = formValues;
    if (dateOfBirth) {
      let age = dayjs().diff(dateOfBirth, "years");
      if (age >= 21) {
        setRenderTitle(true);
      } else {
        setRenderTitle(false);
      }
    }
  }, [formValues]);

  return (
    <>
      {renderTitle && (
        <Grid item xs="auto">
          <Box sx={{ width: "100px" }}>
            <Field
              select
              fullWidth
              component={TextField}
              name="title"
              label="Title"
              variant="filled"
              size="small"
            >
              {Titles.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Field>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name="firstName"
          label="First Name"
          fullWidth
          variant="filled"
          size="small"
          autoFocus
        />
      </Grid>
      <Grid item xs>
        <Field
          component={TextField}
          name="lastName"
          label="Last Name"
          fullWidth
          variant="filled"
          size="small"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name="email"
          label="Email Address"
          fullWidth
          variant="filled"
          size="small"
          disabled={disableEmailFields}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          name="username"
          label="Username"
          fullWidth
          variant="filled"
          size="small"
          disabled={!!formValues.username}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="Usernames cannot change once registered. A username SHOULD NOT be the same as your real name.">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={DatePicker}
          name="dateOfBirth"
          label="Date of Birth"
          maxDate={dayjs().subtract(13, "year").subtract(1, "day")}
          open={dobField}
          onClose={() => setDobField(false)}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              name="dateOfBirth"
              label="Date of Birth"
              variant="filled"
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="We collect your date of birth for account authentication, account recovery, age-appropriate access to content, and for services which you may access that require a minimum age to use.  Kewordal values your privacy and trust.  For more information, please visit our Privacy Policy.">
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setDobField(true)}>
                      <CalendarMonth fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <ErrorMessage
          name="dateOfBirth"
          render={(msg) => <Typography color="error">{msg}</Typography>}
        />
      </Grid>
    </>
  );
};

export default CompleteProfileTextFields;
